import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import TrbmContentAlt from "src/components/modules/trbmcontentAlt"
import CtaDisclosure from "@tightrope/ctadisclosure"
import CtaDisclosureSearch from "@tightrope/ctadisclosuresearch"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import SimplyTemp from "src/components/modules/simplyTemp"
import Pagestyles from "./css/manualpagecss.module.scss"


const prdbestData = import("./data/data.json");

const Img = () => <img className="cws" src="/img/cws-border.png" />;

export default function prdbest() {

  let module;
     module = null;
    if(isChrome() || isIeEdge()){
       module = <TrbmContentAlt data={prdbestData}><Img />
       <CtaDisclosureSearch  data={prdbestData}></CtaDisclosureSearch>
       </TrbmContentAlt>
     }
     else if(isFirefox()){
       module = <TrbmContentAlt data={prdbestData}><Img />
       <CtaDisclosure data={prdbestData}></CtaDisclosure></TrbmContentAlt>
     }
     else if(isSafari()){
      module = <SimplyTemp data={prdbestData}></SimplyTemp>;
    }

     let css;
       if(isFirefox()){
         css = `.trbmcontentAlt-module--trbmcontentAlt .trbmcontentAlt-module--bullets {
           padding-left: 57px!important;
           width: 425px;
         }
         .trbmcontentAlt-module--trbmcontentAlt .trbmcontentAlt-module--bullets li {
           font-size: 15px;
         }
         .cws {
           display: none!important;
         }
           .trbmcontentAlt-module--trbmcontentAlt button {
             padding: 20px 80px;
           }
         #ctadisclosure-module--ctadisclaimer {
             padding: 25px 7% 0!important;
             color: #fafafa;
           }
         }`;
       }
       else{
         css = `.trbmcontentAlt-module--trbmcontentAlt .trbmcontentAlt-module--bullets {
           padding-left: 0!important;
         }
         .cws {
           width: 200px!important;
           display: block!important;
           margin: 20px auto 10px!important;
         }
         #ctadisclosure-module--ctadisclaimer {
           padding: 0px 7%!important;
           padding-bottom: 0!important;

         }`;
       }

  return(
    <HomepageLayout>
      <Helmet>
<style type="text/css">{`

body {
  background-image: url(/img/manualindex-bg.jpeg)!important;
  background-color: #ccc!important;
  background-repeat: no-repeat!important;
  -webkit-background-size: cover !important;;
  -moz-background-size: cover !important;;
  -o-background-size: cover !important;;
  background-size: cover !important;;
  font-family: 'Open Sans', sans-serif;
}

button.trbm_cta {
  font-weight: normal!important;
  font-size: 30px!important;
}

#ctadisclosuresearch-module--ctadisclaimersearch  {
  padding: 0px 7%!important;
  padding-bottom: 0!important;
}

.trbmcontentAlt-module--card {
border: 1px solid #727272!important;
background: rgba(30, 30, 30, 0.8)!important;
}

.trbmcontentAlt-module--bottom { display: none!important;}

h1, h2, .trbmcontentAlt-module--bullets li, #ctadisclosuresearch-module--ctadisclaimersearch {
  color: #f1f1f1!important;
}

.trbmcontentAlt-module--hc_param {
  display: block!important;
    font-weight: bold!important;
    font-size: 25pt!important;
}

.trbmcontentAlt-module--logo {
width: 50px !important;
margin-bottom: 25px !important;
}

.simply-module--simplyWrap .simply-module--atf {
  background:none !important;
}

.simply-module--simplyWrap h1, .simply-module--simplyWrap h3, .simply-module--simplyWrap p {
  color:#fff !important;
}
.simply-module--simplyWrap .simply-module--atf {
  max-height:auto !important;
  background: rgba(30, 30, 30, 0.8)!important;
  width: 800px !important;
  border-radius: 10px;
  border: 1px solid #727272;
  text-align: center;
  box-sizing: border-box;
  padding: 20px !important;
  margin: 40px auto !important;
}

.simply-module--minHeight {
  min-height:100vh;
}

`}
   </style>

   <style type="text/css">
     {css}
         </style>

      <title>Freshy Search - freshysearch.com</title></Helmet>
      <div className = {Pagestyles.manualcws}>

      {module}
      </div>
    </HomepageLayout>
  )
}
